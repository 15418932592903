import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getBotFlowChartDataAsyc } from './botTemplateThunk'
import { downloadJson, getCookie, getProper, getUserData } from '../../utilities/chatbotMethods'



//@DEF - getting the data from the data/templates/Inquiry.js file  and initializing the state
//@VARIABLE - inquiry
//@TYPE - object

const initialState = {
    isLoading : true,
    template : null,
    botId : null,
    selectedNode : null,
    nodeMenuPosition : null,
    nodeToBeFocused : 0,
    showNodeDropdown : null,
    showNodeSelected : null,
    buttonOrCardDelete : null
    
    
}


//thunk
export const getBotFlowChartData = createAsyncThunk('botTemplate/getBotFlowChartData' , getBotFlowChartDataAsyc)


//creating slice -- botTemplateSlice
//contains reducer and actions and actions creators

const debounce = (func, delay) => {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const sendPostMessage = (data) => {
  const iframe = document.getElementById('patil');
  
    
    if (iframe && iframe.contentWindow) {
      console.log("SENDING DATA",iframe && iframe.contentWindow)
      const dataToSend = {
        steps: JSON.parse(sessionStorage.getItem("aiSteps")),
        name: localStorage.getItem("BOTNAME") || "AI Bot",
        type: '17',
        page: "editor"
      };
      debouncedSendPostMessage(dataToSend);
    }
 
  
  
};

const debouncedSendPostMessage = debounce(sendPostMessage, 500);

const firstHitofData = (data) => {
  const iframe = document.getElementById("patil");
  
  var intV = setInterval(() => {
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(data, "*");
    }
  }, 500);
  const handleMessage = (event) => {
    // Check the origin of the message for security
    console.log(event.origin, "MESSAGE IN PARENT");
    if (event.data?.loader) {
      clearInterval(intV);
      window.removeEventListener("message", handleMessage);
    }
  };
  window.addEventListener("message", handleMessage);
};
// Wrap the sendPostMessage function with debounce
export const botTemplateSlice = createSlice({
  name: 'botTemplate',
  initialState,
  reducers: {
    updateTemplate : (state , action) => {
        state.template = action.payload
       if(action.payload.initialHit){
        // const userData = await getUserData(getCookie("APPYID"))

        let dataToSend = {
          steps: action.payload.template_One,
          isCanopyIndex:JSON.parse(localStorage.canopyBots).includes(localStorage.botId),
          name: localStorage.getItem('BOTNAME'),
          type: localStorage.getItem('botType'),
          bot_email: localStorage.getItem('email'),
          page: window.location.href.includes("editor") ? "editor" : "design"
          
        }
        firstHitofData(dataToSend)
       }else{
        
        const iframe = document.getElementById('patil');
        if(iframe&&iframe.contentWindow){

          // const userData = await getUserData(getCookie("APPYID"))
          let dataToSend = {
            steps: action.payload.template_One,
            isCanopyIndex:JSON.parse(localStorage.canopyBots).includes(localStorage.botId),
            name: localStorage.getItem('BOTNAME'),
            type: localStorage.getItem('botType'),
            bot_email: localStorage.getItem('email'),
            page: window.location.href.includes("editor") ? "editor" : "design"
            
          }
        // debugger
        debouncedSendPostMessage(dataToSend);
       
        }
      }
      localStorage.removeItem("email")
    },

    updateSelectedNode : (state , action) => {
        var data =JSON.parse(JSON.stringify(action.payload))
        if(data&&data.data&&data.data.data){
        for (const key in data.data.data) {
          if (data.data.data.hasOwnProperty(key) && typeof data.data.data[key] === 'string') {
            data.data.data[key] = getProper(data.data.data[key]);
          }
        }
        }
        // console.log(data.data.data,"DATA IN SELECTED NODE")
        state.selectedNode =data
    },
    updateNodeMenuPosition : (state , action) => {
      state.nodeMenuPosition = action.payload
    },
    updateNodeToBeFocused : (state , action) => {
      state.nodeToBeFocused = action.payload
    },
    resetTemplate : (state , action) => {
      state.template = null
      state.isLoading = true
      
    },
    setBotId : (state , action) => {
      state.botId = action.payload
    },
    setShowNodeDropdown : (state , action) => {
      state.showNodeDropdown = action.payload
    },
    resetSelectedNode : (state) => {
      state.selectedNode = null
    },
    setShowNodeSelected : (state , action) => {
      state.showNodeSelected = action.payload
    },
    setButtonOrCardDelete : (state , action) => {
      state.buttonOrCardDelete = action.payload
    }
    

    
  },
  extraReducers : (builder) => {
    builder.addCase(getBotFlowChartData.fulfilled , (state , action) => {
      // console.log(action.payload , "payload")
      state.isLoading = false
    }).addCase(getBotFlowChartData.pending , (state , action) => {
      state.isLoading = true
    })
    // builder.addCase(toggleBotStatus.fulfilled , (state , action) => {
    //   console.log(action.payload , "payload")
    //   state.userBots = {
    //     ...state.userBots,
    //     [action.payload.botId] : {
    //       ...state.userBots[action.payload.botId],
    //       status : action.payload.newStatus
    //     }
    //   }
    // })
  }
})




export const {setButtonOrCardDelete , resetSelectedNode , setShowNodeSelected ,  resetTemplate , updateTemplate , updateSelectedNode  ,updateNodeMenuPosition , updateNodeToBeFocused , setShowNodeDropdown} =  botTemplateSlice.actions


//exporting reducer -- botTemplateReducer
//state -- {botTemplate : Object}
export default botTemplateSlice.reducer